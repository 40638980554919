/**
 * 搜索头
 */
export const searchHeaderOptions = [
  {
    type: "select",
    field: "direction",
    label: "收支方向",
    iw: 160,
    defaultValue: null,
    options: []
  },
  {
    field: ["start_time", "end_time"],
    type: "dateRange",
    label: "操作时间",
    iw: 320
  }
];
