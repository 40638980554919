<template>
  <div class="page-main-box">
    <!-- 有数据 -->
    <div class="pt-24 pl-16 pr-16">
      <div class="d-flex ml-24 mb-24">
        <p>
          当前查询企业：
          <span class="fw-600">{{ $route.query.company_name }}</span>
        </p>
        <p class="ml-48">
          账户类型：
          <span class="fw-600">{{ $route.query.account_type_desc }}</span>
        </p>
        <p class="ml-48">
          服务：
          <span class="fw-600">{{
            $route.query.account_type_desc === "通用账户"
              ? "-"
              : $route.query.account_service_desc
          }}</span>
        </p>
      </div>

      <!-- 搜索头 -->
      <search-header :schemaData="schema" @getDataList="handleFormSearch" />

      <!-- 列表 -->
      <div :style="{ height: tableHeight + 'px' }">
        <p-table
          id="account-detail-table"
          :dataSource="tableDataSource"
          height="auto"
        >
          <p-t-column type="seq" width="120" title="序列" />
          <p-t-column field="card_no" min-width="165" title="卡号" />
          <p-t-column field="direction_desc" min-width="148" title="收支方向" />
          <p-t-column
            field="money"
            min-width="169"
            title="余额（元）"
            align="right"
          >
            <template v-slot="{ row }">
              <span>{{ (row.money / 100).toFixed(2) }}</span>
            </template>
          </p-t-column>
          <p-t-column field="operating_time" min-width="106" title="操作时间">
            <template v-slot="{ row }">
              <span>{{
                formatToDateTime(row.operating_time, "yyyy-MM-dd")
              }}</span>
            </template>
          </p-t-column>
          <p-t-column width="120" title="操作" fixed="right">
            <template v-slot="{ row }">
              <Button
                type="link"
                @click="handleSeviceConfig(row)"
                v-if="
                  (row.direction === 'IN' &&
                    $store.getters.roles.includes('RechargeWithDraw')) ||
                    (row.direction === 'OUT' &&
                      $store.getters.roles.includes('ServiceOrder'))
                "
              >
                订单明细
              </Button>
            </template>
          </p-t-column>

          <template #footer>
            <a-button class="mt-12 ml-32" @click="handleExport" type="primary"
              >导出</a-button
            >
          </template>
        </p-table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, toRaw } from "vue";
import { Button } from "ant-design-vue";
import { Table } from "@wlhy-web-components/common";
import { searchHeaderOptions } from "./Constants";
import { useRoute, useRouter } from "vue-router";
import { useDailyBillQueryApi, useDailyBillExportApi } from "@/apis/financial";
import { useEnumAccountDirectionApi } from "@/apis/enums";
import $await from "@/utils/await";
import useTableDataRecords from "@/hooks/useTableDataRecords";
import useSearchForm from "@/hooks/UseSearchForm";
import { formatToDateTime } from "@wlhy-web-lib/shared";
import { exportExcel } from "@/hooks/useExport";
import useTableHeight from "@/hooks/useTableHeight";
import useSearch from "@/hooks/useSearch";

export default {
  name: "account-detail",
  components: { Button },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const dailyBillQueryApi = useDailyBillQueryApi();
    const dailyBillExportApi = useDailyBillExportApi();
    const enumAccountDirectionApi = useEnumAccountDirectionApi();

    // table高度
    const tableHeight = ref(null);

    const account_id = route.query.id;

    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    let schemaList = searchHeaderOptions;

    (async () => {
      let [, res] = await $await(enumAccountDirectionApi());
      schemaList = useSearch(searchHeaderOptions, res, "direction");
    })();

    const { filter, handleFormSearch } = useSearchForm(schemaList);

    Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...toRaw(filter.value),
          account_id: account_id,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const [, res] = await $await(dailyBillQueryApi(data));
        return useTableDataRecords(res);
      }
    });

    // 操作栏跳转
    const handleSeviceConfig = row => {
      if (row.direction === "OUT") {
        router.push({
          path: "/order/service-order",
          query: { id: row.account_id, time: row.operating_time }
        });
      } else {
        router.push({
          path: "/financial/recharge-withdraw",
          query: { id: row.company_id, time: row.operating_time }
        });
      }
    };

    // 导出
    const handleExport = async () => {
      const data = {
        ...toRaw(filter.value),
        account_id: account_id,
        company_name: route.query.company_name
      };
      const [, res] = await $await(dailyBillExportApi(data));
      exportExcel(res, "账户详情");
    };

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    return {
      tableHeight,
      tableDataSource,
      handleFormSearch,
      handleExport,
      formatToDateTime,
      handleSeviceConfig,
      schema: searchHeaderOptions
    };
  }
};
</script>

<style lang="less" scoped>
#serviceOrderBox {
  position: relative;
  overflow: hidden;
}
</style>
